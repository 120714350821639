/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React, { useRef } from "react";
import loadable from "@loadable/component";

import { useAppState } from "@state";
import useLakotaAcFooterData from "@staticQueries/lakota/FooterQuery";
import useLgcaAcFooterData from "@staticQueries/lgcaAc/FooterQuery";
import useRomeroFooterData from "@staticQueries/romero/FooterQuery";

import brand from "@utils/brand";
import filterActions from "@utils/filterActions";

const LakotaFooter = loadable(() => import("@organisms/LakotaFooter"));
const LgcaAcFooter = loadable(() => import("@organisms/LgcaAcFooter"));
const RomeroFooter = loadable(() => import("@organisms/RomeroFooter"));
const Cta = loadable(() => import("@organisms/Cta"));

const PageContainer = ({ children, className, path }) => {
  const [{ layout, ea }] = useAppState();
  const { lgca, lakota, romero } = brand;
  const { footerNav: footerNavLakota, actions: actionsLakota } =
    useLakotaAcFooterData();
  const { footerNav: footerNavLgca, actions: actionsLgca } =
    useLgcaAcFooterData();
  const { footerNav: footerNavRomero, actions: actionsRomero } =
    useRomeroFooterData();

  const actions = {
    lakota: actionsLakota,
    lgca: actionsLgca,
    romero: actionsRomero,
  }[brand.brand];

  const upNext =
    actions?.length && filterActions(actions, ea.activistCodes || []);

  const action = upNext?.[0] || actions[(actions?.length || 0) - 1];
  const { theme, template } = layout;
  const isAction = template === "action";

  const page = useRef();

  const entered = true;

  return (
    <div
      ref={page}
      id="ac-layout"
      className={`
      ${className}
      ${theme === "dark" ? "bg-brown" : "bg-cream"}
      ${!entered ? "opacity-0" : ""}
      flex min-h-screen flex-grow flex-col`}
    >
      <main className="flex-grow">{children}</main>
      <footer>
        {!isAction && action && (
          <Cta
            heading={action.heading}
            copy={action.copy}
            icon="buffalo"
            backgroundImage={action.backgroundImage}
            action={action}
            showAction
            path={path}
          />
        )}
        {lakota && <LakotaFooter nav={footerNavLakota} />}
        {lgca && <LgcaAcFooter nav={footerNavLgca} />}
        {romero && <RomeroFooter nav={footerNavRomero} />}
      </footer>
    </div>
  );
};

PageContainer.defaultProps = {
  className: "",
  isAction: false,
};

export default PageContainer;
